import React, { useState, useEffect } from "react";
import { useModalContext } from "../context/ModalContext";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [showSignup, setShowSignup] = useState(true);
  const { toggleModal } = useModalContext();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const updateButtonVisibility = () => {
    const scrollY = window.scrollY;

    const atTop = scrollY === 0;

    const heroSection = document.querySelector("#hero");
    const heroVisible =
      heroSection &&
      heroSection.getBoundingClientRect().top < window.innerHeight &&
      heroSection.getBoundingClientRect().bottom > 0;

    const formSection = document.querySelector("#form");
    const formVisible =
      formSection &&
      formSection.getBoundingClientRect().top < window.innerHeight &&
      formSection.getBoundingClientRect().bottom > 0;

    if (atTop || heroVisible || formVisible) {
      setShowSignup(false);
    } else {
      setShowSignup(true);
    }
  };

  useEffect(() => {
    updateButtonVisibility();
    window.addEventListener("scroll", updateButtonVisibility);

    return () => {
      window.removeEventListener("scroll", updateButtonVisibility);
    };
  }, []);

  return (
    <header className="header bg-red-700">
      <img src="assets/img/logo.png" className="logo" alt="Berth Engineering" />
      <nav className={`menu ${menuOpen ? "show" : ""}`}>
        <a href="#hero" onClick={closeMenu}>
          Home
        </a>
        <a href="#about" onClick={closeMenu}>
          About
        </a>
        <a href="#course" onClick={closeMenu}>
          Course
        </a>
        <a href="#features" onClick={closeMenu}>
          Features
        </a>
        <a href="#faq" onClick={closeMenu}>
          FAQ
        </a>
      </nav>
      <div className="header-btns">
        <div
          className={`signup ${showSignup ? "fade-in" : "fade-out"}`}
          onClick={toggleModal}
        >
          Sign Up
        </div>
      </div>
      <div
        className={`hamburger ${menuOpen ? "open" : ""}`}
        onClick={toggleMenu}
      >
        <div></div>
        <div></div>
        <div></div>
      </div>
    </header>
  );
};

export default Header;
