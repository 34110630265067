import React, { createContext, useContext, useState } from "react";

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);

  const toggleModal = () => setModalOpen((prev) => !prev);

  return (
    <ModalContext.Provider
      value={{ toggleModal, isModalOpen, selectedPlan, setSelectedPlan }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export const useModalContext = () => useContext(ModalContext);
