import React from "react";

const ServicesSection = () => {
  const services = [
    {
      iconSRC:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/7/74/Kotlin_Icon.png/1200px-Kotlin_Icon.png",
      title: "Kotlin Basics",
      description: "Lorem ipsum dolor blah blah blah.",
    },
    {
      iconSRC:
        "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjC97Z8BResg5dlPqczsRCFhP6zewWX0X0e7fVPG-G7PuUZwwZVsi9OPoqJYkgqT2h0FI95SsmWzVEgpt8b8HAqFiIxZ98TFtY4lE0b8UrtVJ2HrJebRwl6C9DslsQDl9KnBIrdHS6LtkY/s1600/jetpack+compose+icon_RGB.png",
      title: "Jetpack Compose",
      description: "Lorem ipsum dolor blah blah blah.",
    },
    {
      iconSRC: "https://cdn-icons-png.freepik.com/512/77/77177.png",
      title: "UI Design",
      description: "Lorem ipsum dolor blah blah blah.",
    },
    {
      iconSRC:
        "https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Android_robot_%282014-2019%29.svg/151px-Android_robot_%282014-2019%29.svg.png",
      title: "Android Architecture",
      description: "Lorem ipsum dolor blah blah blah.",
    },
    {
      iconSRC:
        "https://i1.wp.com/9to5google.com/wp-content/uploads/sites/4/2022/07/current-google-play-icon.jpg",
      title: "Publishing on Play Store",
      description: "Lorem ipsum dolor blah blah blah.",
    },
    {
      iconSRC:
        "https://i1.wp.com/9to5google.com/wp-content/uploads/sites/4/2022/07/current-google-play-icon.jpg",
      title: "One more thing..",
      description: "Lorem ipsum dolor blah blah blah.",
    },
  ];

  return (
    <section className="services-section" id="features">
      <div className="services-header">
        <div className="header-left">
          <i className="iconoir-check-circle-solid"></i>
          <h3>Masterclass</h3>
          <h2 className="h2">What You’ll Learn</h2>
        </div>
        <div className="header-right">
          <p>
            By the end of the course, you’ll have the confidence and skills to
            build professional-grade Android applications and launch them for
            the world to use!
          </p>
        </div>
      </div>

      <div className="services-list">
        {services.map((service, index) => (
          <div className="service-card" key={index}>
            <div className="service-icon">
              <img src={service.iconSRC} className="service-icon" alt="" />
            </div>
            <h3 className="title">{service.title}</h3>
            <p style={{ visibility: "hidden" }}>{service.description}</p>
            {service.badge && (
              <span className="service-badge">{service.badge}</span>
            )}
          </div>
        ))}
      </div>
    </section>
  );
};

export default ServicesSection;
