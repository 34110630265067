import React, { useEffect } from "react";
import { useModalContext } from "../context/ModalContext";
import WaitingList from "./WaitingList/WaitingList";

const Modal = () => {
  const { isModalOpen, toggleModal } = useModalContext();

  useEffect(() => {
    const originalStyle = {
      overflow: window.getComputedStyle(document.body).overflow,
      paddingRight: window.getComputedStyle(document.body).paddingRight,
    };

    if (isModalOpen) {
      const scrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;
      document.body.style.overflow = "hidden";
      document.body.style.paddingRight = `${scrollbarWidth}px`;
    } else {
      document.body.style.overflow = originalStyle.overflow;
      document.body.style.paddingRight = originalStyle.paddingRight;
    }

    return () => {
      document.body.style.overflow = originalStyle.overflow;
      document.body.style.paddingRight = originalStyle.paddingRight;
    };
  }, [isModalOpen]);

  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      toggleModal();
    }
  };

  if (!isModalOpen) return null;

  return (
    <div
      className="modal-overlay"
      onClick={handleOverlayClick}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        background: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        animation: "fadeIn 0.3s forwards",
      }}
    >
      <div style={{ width: "90%" }}>
        <WaitingList toggleModal={toggleModal} />
      </div>
    </div>
  );
};

export default Modal;
