import React from "react";

const CourseOutline = () => {
  const modules = [
    { id: 1, title: "Introduction to the Course", description: "Overview of course objectives, structure, and key topics." },
    { id: 2, title: "Module 1: Intro to Kotlin", description: "Intro to Kotlin." },
    { id: 3, title: "Module 2: Layouts & Components in Jetpack Compose", description: "In-depth exploration of intermediate-level topics." },
    { id: 4, title: "Module 3: Networking & Data Storage", description: "Applying knowledge in advanced scenarios and case studies." },
    { id: 5, title: "Module 4: Publishing on the Play Store", description: "Summarizing key points and preparing for the final exam." },
  ];

  return (
    <section className="course-outline" id="course">
      <div className="outline-header">
        <h3>Course Modules</h3>
        <h2 className="h2">Module Outline</h2>
        <p>Follow the structured modules to build a comprehensive understanding of the course content.</p>
      </div>

      <div className="outline-content">
        <div className="features">
          <p><span className="checkmark"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}><path d="m7 12.5l3 3l7-7"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path></g></svg></span> Kotlin Basics</p>
          <p><span className="checkmark"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}><path d="m7 12.5l3 3l7-7"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path></g></svg></span> Jetpack Compose</p>
          <p><span className="checkmark"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}><path d="m7 12.5l3 3l7-7"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path></g></svg></span> UI Design</p>
          <p><span className="checkmark"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}><path d="m7 12.5l3 3l7-7"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path></g></svg></span> Android Architecture</p>
          <p><span className="checkmark"><svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24"><g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5}><path d="m7 12.5l3 3l7-7"></path><path d="M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"></path></g></svg></span> Publish on Play Store</p>
        </div>

        <div className="modules">
          {modules.map((module) => (
            <div key={module.id} className="module">
              <div className="circle">{module.id}</div>
              <div className="module-content">
                <h3>{module.title}</h3>
                <p>{module.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default CourseOutline;
