import React, { useState, useEffect } from "react";
import styles from "./WaitingList.module.css";
import { useModalContext } from "../../context/ModalContext";
import { Icon } from "@iconify/react/dist/iconify.js";
import { toast } from "react-hot-toast";

function WaitingList({ toggleModal }) {
  const { selectedPlan } = useModalContext();
  const [formData, setFormData] = useState({
    fullName: "",
    emailAddress: "",
    phoneNumber: "",
    profileUrl: "",
    experienceLevelProgramming: "",
    experienceLevelAndroid: "",
    goals: "",
    interestedTopics: "",
    commitment: "",
    sessions: "",
    communication: "",
    paymentMethod: "",
    paymentType: "Monthly", // Default payment type
    amount: "1500", // Default amount for "Monthly"
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);

  // Update amount dynamically based on paymentType
  useEffect(() => {
    const updatedAmount = formData.paymentType === "Monthly" ? "1500" : "4300";
    setFormData((prev) => ({ ...prev, amount: updatedAmount }));
  }, [formData.paymentType]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    e.target.setCustomValidity("");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { ...formData };
    console.log("Submitting payload:", payload);

    setIsSubmitting(true);
    setSubmissionStatus(null);

    try {
      const response = await fetch(
        "https://prod-27.southafricanorth.logic.azure.com:443/workflows/d8ab0b3a13114406aa202d5ae5685050/triggers/When_a_HTTP_request_is_received/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2FWhen_a_HTTP_request_is_received%2Frun&sv=1.0&sig=tAiNAYaOKdpJL6HCxSTDa5Nh400NKa419o594KqxajM",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Server response:", result);
        toast.success("Successfully submitted");

        setSubmissionStatus("success");
        toggleModal();
      } else {
        console.error("Server error:", response.status, response.statusText);
        setSubmissionStatus("error");
        alert(`Server error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Submission error:", error);
      setSubmissionStatus("error");
      alert("There was an error submitting the form. Please try again.");
    } finally {
      setIsSubmitting(false);
    }
  };

  const setValidationMessage = (e, message) => {
    if (!e.target.value) {
      e.target.setCustomValidity(message);
    }
  };

  return (
    <section className={styles["form-section"]}>
      <h2 className={styles["section-header"]}>Join the Waiting List</h2>
      <Icon
        icon="system-uicons:close"
        fontSize={45}
        style={{ position: "absolute", top: 10, right: 10, cursor: "pointer" }}
        onClick={toggleModal}
      />
      <form onSubmit={handleSubmit}>
        {/* Section 1 */}
        <h3 className={styles["section-header"]}>
          1. Personal & Contact Information
        </h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Full Name
            <input
              type="text"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
              className={styles["input-field"]}
              placeholder="Steve Jobs"
              onInvalid={(e) =>
                setValidationMessage(e, "Please enter your full name.")
              }
            />
          </label>
          <label className={styles["form-label"]}>
            Email Address
            <input
              type="email"
              name="emailAddress"
              value={formData.emailAddress}
              onChange={handleChange}
              required
              className={styles["input-field"]}
              placeholder="stevejobs@example.com"
              onInvalid={(e) =>
                setValidationMessage(e, "Please enter a valid email address.")
              }
            />
          </label>
          <label className={styles["form-label"]}>
            Phone Number
            <input
              type="tel"
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={handleChange}
              required
              className={styles["input-field"]}
              placeholder="0244130000"
              onInvalid={(e) =>
                setValidationMessage(e, "Please enter a valid phone number.")
              }
            />
          </label>
          <label className={styles["form-label"]}>
            LinkedIn or GitHub Profile (optional)
            <input
              type="url"
              name="profileUrl"
              value={formData.profileUrl}
              onChange={handleChange}
              className={styles["input-field"]}
              placeholder="https://github.com/username"
            />
          </label>
        </div>

        {/* Section 2 */}
        <h3 className={styles["section-header"]}>
          2. Experience Level Programming
        </h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Current experience level programming:
            <select
              name="experienceLevelProgramming"
              value={formData.experienceLevelProgramming}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select your experience level.")
              }
            >
              <option value="">Select your experience level</option>
              <option value="Beginner">Beginner</option>
              <option value="Intermediate">Intermediate</option>
              <option value="Advanced">Advanced</option>
            </select>
          </label>
          <label className={styles["form-label"]}>
            Familiarity with Android development:
            <select
              name="experienceLevelAndroid"
              value={formData.experienceLevelAndroid}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(
                  e,
                  "Please select your familiarity level with Android development."
                )
              }
            >
              <option value="">Select your familiarity level</option>
              <option value="No experience">No experience</option>
              <option value="Some experience">Some experience</option>
              <option value="Proficient">Proficient</option>
            </select>
          </label>
        </div>

        {/* Section 3 */}
        <h3 className={styles["section-header"]}>3. Learning Goals</h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Goals for this course:
            <select
              name="goals"
              value={formData.goals}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select your goals.")
              }
            >
              <option value="">Select your goals</option>
              <option value="Learn Android basics">Learn Android basics</option>
              <option value="Career transition">Career transition</option>
              <option value="Launch a project">Launch a project</option>
            </select>
          </label>
          <label className={styles["form-label"]}>
            Which specific topics are you most interested in?
            <select
              name="interestedTopics"
              value={formData.interestedTopics}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select a topic of interest.")
              }
            >
              <option value="">Select topics</option>
              <option value="Kotlin basics">Kotlin basics</option>
              <option value="Jetpack Compose">Jetpack Compose</option>
              <option value="Android architecture">Android architecture</option>
            </select>
          </label>
        </div>

        {/* Section 4 */}
        <h3 className={styles["section-header"]}>
          4. Availability and Commitment
        </h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Weekly commitment:
            <select
              name="commitment"
              value={formData.commitment}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select your weekly commitment.")
              }
            >
              <option value="">Select your weekly commitment</option>
              <option value="1-2 hours">1-2 hours</option>
              <option value="3-5 hours">3-5 hours</option>
              <option value="6+ hours">6+ hours</option>
            </select>
          </label>
          <label className={styles["form-label"]}>
            Session
            <select
              name="sessions"
              value={formData.sessions}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select your session")
              }
            >
              <option value="">Select your session</option>
              <option value="Live Sessions">Live Sessions</option>
            </select>
          </label>
        </div>

        {/* Section 5 */}
        <h3 className={styles["section-header"]}>5. Communication & Payment</h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Referral source:
            <select
              name="communication"
              value={formData.communication}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select how you heard about us.")
              }
            >
              <option value="">Select a source</option>
              <option value="Referral">Referral</option>
              <option value="Social Media">Social Media</option>
              <option value="Website">Website</option>
            </select>
          </label>
          <label className={styles["form-label"]}>
            Preferred payment method:
            <select
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
              required
              className={styles["select-field"]}
              onInvalid={(e) =>
                setValidationMessage(e, "Please select a payment method.")
              }
            >
              <option value="">Select a payment method</option>
              <option value="Credit card">Credit card</option>
              <option value="Mobile money">Mobile money</option>
              <option value="Bank transfer">Bank transfer</option>
            </select>
          </label>
        </div>

        {/* Section 6 */}
        <h3 className={styles["section-header"]}>6. Payment Type & Amount</h3>
        <div className={styles["two-column"]}>
          <label className={styles["form-label"]}>
            Payment Type:
            <select
              name="paymentType"
              value={formData.paymentType}
              onChange={handleChange}
              required
              className={styles["select-field"]}
            >
              <option value="Monthly">Monthly</option>
              <option value="One time fee">One-Time Fee</option>
            </select>
          </label>
          <label className={styles["form-label"]}>
            Amount:
            <input
              type="text"
              name="amount"
              value={formData.amount}
              readOnly
              className={styles["input-field"]}
            />
          </label>
        </div>

        <button
          type="submit"
          className={styles["submit-button"]}
          disabled={isSubmitting}
        >
          {isSubmitting ? "Submitting..." : "Reserve Your Spot"}
        </button>
        {submissionStatus === "success" && (
          <p style={{ color: "green", marginTop: "10px" }}>
            Form submitted successfully!
          </p>
        )}
        {submissionStatus === "error" && (
          <p style={{ color: "red", marginTop: "10px" }}>
            There was an error submitting the form. Please try again.
          </p>
        )}
      </form>
    </section>
  );
}

export default WaitingList;
