import React, { useState } from "react";
import Accordion from "./Accordion/Accordion";

const benefits = [
  {
    title: "Comprehensive Curriculum",
    content:
      "Learn everything from Kotlin programming basics to advanced Android development with Jetpack Compose, culminating in launching your own app on the Google Play Store",
  },
  {
    title: "Project-Based Learning",
    content:
      "Gain hands-on experience by building real-world Android applications, helping you solidify your skills and build an impressive portfolio",
  },
  {
    title: "Expert Instruction",
    content:
      "Learn directly from Gideon Bedzrah, an experienced software engineer with a proven track record in enterprise software development, mobile apps, and artificial intelligence",
  },
  {
    title: "Certificate of Completion",
    content:
      "Earn a professional certificate to showcase your new skills to employers, clients, or your network",
  },
  {
    title: "Small Class Sizes",
    content:
      "Enjoy personalized support and mentorship, ensuring you have all the guidance you need to succeed",
  },
  {
    title: "Flexible Learning Options",
    content:
      "Participate in live sessions with recorded classes available for review, fitting seamlessly into your schedule",
  },
];

const FeaturesSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const handleToggle = (index) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  return (
    <section className="features-section" id="features">
      <div className="left-column">
        <h3 className="h3">Why Choose This Course</h3>
        <h2 className="h2">Key Benefits</h2>
        <p className="p">
          This course is perfect for beginners and developers looking to
          specialize in Android development!
        </p>
        {/* <ul className="checklist">
          <li>
            <Icon icon="fluent-color:checkbox-20" fontSize={27} />
            Expert-Led Sessions
          </li>
          <li>
            <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75S22.75 17.937 22.75 12S17.937 1.25 12 1.25M7.53 11.97a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l7-7a.75.75 0 0 0-1.06-1.06L10 14.44z"
                clipRule="evenodd"
              ></path>
            </svg>{" "}
            Hands-On Projects
          </li>
          <li>
            <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75S22.75 17.937 22.75 12S17.937 1.25 12 1.25M7.53 11.97a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l7-7a.75.75 0 0 0-1.06-1.06L10 14.44z"
                clipRule="evenodd"
              ></path>
            </svg>{" "}
            Guidance on Play Store Launch
          </li>
          <li>
            <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="currentColor"
                fillRule="evenodd"
                d="M12 1.25C6.063 1.25 1.25 6.063 1.25 12S6.063 22.75 12 22.75S22.75 17.937 22.75 12S17.937 1.25 12 1.25M7.53 11.97a.75.75 0 0 0-1.06 1.06l3 3a.75.75 0 0 0 1.06 0l7-7a.75.75 0 0 0-1.06-1.06L10 14.44z"
                clipRule="evenodd"
              ></path>
            </svg>{" "}
            Career-Boosting Skills
          </li>
        </ul> */}
        <div className="accordion-list">
          {benefits.map((benefit, index) => (
            <Accordion
              key={index}
              title={benefit.title}
              content={benefit.content}
              isOpen={openIndex === index}
              onToggle={() => handleToggle(index)}
            />
          ))}
        </div>
      </div>
      <div className="right-column">
        <div className="card-grid">
          <div className="features-card">
            <img
              className="w-full rounded-lg bg-white object-cover group-hover:opacity-75 max-sm:h-80 sm:aspect-[2/1] lg:aspect-square"
              src="https://thumbs.dreamstime.com/b/android-logo-mobile-phone-screen-smartphone-over-laptop-showing-new-311783768.jpg"
              alt="Card 1"
            />
          </div>
          <div className="features-card">
            <img
              src="https://gizmodo.com/app/uploads/2024/07/Google-Play-.jpg"
              alt="Card 2"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;
