import React from "react";
import { useModalContext } from "../context/ModalContext";

const HeroSection = () => {
  const { toggleModal } = useModalContext();

  return (
    <section className="hero" id="hero">
      <div className="hero-text">
        <img src="assets/img/kotlin.png" className="kotlin" alt="Kotlin Logo" />
        <h1>
          Master Android Development with Kotlin & Jetpack Compose – From
          Beginner to Play Store Ready.
        </h1>
        <p>
          Learn to build and launch a real-world app, guided by industry experts
        </p>
        <div className="buttons">
          <div className="hero-btn" onClick={toggleModal}>
            Join The Waiting List
            <svg
              className="icon"
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1.5}
                d="M8 12h8m0 0l-3.5-3.5M16 12l-3.5 3.5M12 22c5.523 0 10-4.477 10-10S17.523 2 12 2S2 6.477 2 12s4.477 10 10 10"
              ></path>
            </svg>
          </div>
          <p
            style={{
              fontSize: "14px",
              fontFamily: "Rubik",
              letterSpacing: "1px",
            }}
          >
            No initial payment required!
          </p>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
