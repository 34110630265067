import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import styles from "./Accordion.module.css";

const Accordion = ({ title, content, isOpen, onToggle }) => {
  return (
    <div className={styles.accordion}>
      <div className={styles.accordionHeader} onClick={onToggle}>
        <span>{title}</span>
        <Icon
          icon={isOpen ? "mdi:chevron-up" : "mdi:chevron-down"}
          className={styles.icon}
        />
      </div>
      <div
        className={`${styles.accordionContent} ${isOpen ? styles.open : ""}`}
      >
        <p style={{ fontSize: "16px" }}>{content}</p>
      </div>
    </div>
  );
};

export default Accordion;
