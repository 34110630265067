import React, { useState } from 'react';

const FaqSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is the Android Development Masterclass about?",
      answer: "The masterclass is a comprehensive course designed to teach participants how to build Android applications using Kotlin and Jetpack Compose. From learning the basics of Kotlin to designing user interfaces and publishing an app on the Google Play Store, the course is structured to guide you every step of the way."
    },
    {
      question: "Who is this course for?",
      answer: "This course is perfect for:\n• Beginners with no prior programming experience who want to learn Android development.\n• Developers looking to transition into mobile app development.\n• Professionals wanting to add Jetpack Compose to their skill set.\n• Entrepreneurs aiming to create and launch their own apps."
    },
    {
      question: "Do I need any prior experience to join the course?",
      answer: "No prior experience in Android development is required. However, a basic understanding of programming concepts can be helpful. We’ll start with Kotlin basics and build up from there."
    },
    {
      question: "What topics will be covered in the course?",
      answer: "The course covers:\n• Kotlin Basics: Syntax, functions, and object-oriented programming.\n• Jetpack Compose: Building modern, responsive UI components.\n• Android Architecture: ViewModels, navigation, and best practices.\n• Networking: API integration and data persistence.\n• Play Store Publishing: Preparing and launching your app on the Play Store."
    },
    {
      question: "How will the course be delivered?",
      answer: "The course will be delivered through:\n• Live Sessions: Interactive lectures with Q&A.\n• Hands-On Projects: Build real-world applications as you learn.\n• Recordings: Access recorded sessions for self-paced learning.\n• Resource Materials: Get access to slides, code snippets, and exercises."
    },
    {
      question: "What is the duration of the masterclass?",
      answer: "The masterclass runs for 10 weeks, with live sessions held twice a week. Each session will last approximately 2 hours. You’ll also have access to course materials for self-paced study."
    },
    {
      question: "Will I receive a certificate after completing the course?",
      answer: "Yes! Participants who complete the course and submit the final project will receive a Certificate of Completion, showcasing their skills and achievements."
    },
    {
      question: "What tools or software do I need?",
      answer: "You’ll need:\n• A laptop or desktop computer (Windows, macOS, or Linux).\n• An Android smartphone for testing apps (optional).\n• Android Studio (free IDE) installed on your computer.\nWe’ll guide you through the setup during the first session."
    },
    {
      question: "What is the cost of the masterclass?",
      answer: "The course fee is $300, which covers all live sessions, materials, and lifetime access to recordings. Early bird discounts and group rates are also available."
    },
    {
      question: "How can I pay for the course?",
      answer: "We accept the following payment methods:\n• Mobile money\n• Bank transfer\n• Credit/debit card\nPayment instructions will be provided upon registration."
    },
    {
      question: "What if I miss a live session?",
      answer: "No worries! All live sessions will be recorded and made available for you to access at your convenience."
    },
    {
      question: "Can I ask questions during the course?",
      answer: "Absolutely! During live sessions, you’ll have opportunities to interact with the instructor and ask questions. You can also reach out via the course forum or email support between sessions."
    },
    {
      question: "Will I build a real app during the course?",
      answer: "Yes! By the end of the course, you’ll have built and published a functional Android app, complete with user interfaces, navigation, and API integrations."
    },
    {
      question: "How do I sign up?",
      answer: "Click the Join the Waiting List button on this page to register your interest. You’ll receive further instructions, including payment details and the course schedule."
    },
    {
      question: "Can I get a refund if I’m not satisfied?",
      answer: "We offer a refund policy for participants who cancel before the start of the masterclass. Once the course begins, refunds will not be provided, but you’ll retain lifetime access to the recordings and materials."
    },
    {
      question: "Who will be teaching the course?",
      answer: "The course is taught by Gideon Bedzrah, founder of Berth Engineering, with extensive experience in Android development and mobile application design. Gideon will guide you through both technical skills and industry best practices."
    }
  ];

  const toggleFaq = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  return (
    <section className="faq-section" id="faq">
      <h3>FAQ Section</h3>
      <h2 className="h2">Frequently Asked Questions</h2>
      <div className="faq-container">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className={`faq-item ${activeIndex === index ? 'active' : ''}`}
            onClick={() => toggleFaq(index)}
          >
            <div className="faq-question">
              {faq.question}
              <span>{activeIndex === index ? '-' : '+'}</span>
            </div>
            <div
              className="faq-answer"
              style={{
                maxHeight: activeIndex === index ? '500px' : '0',
                transition: 'max-height 0.3s ease',
              }}
            >
              <p>{faq.answer}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default FaqSection;