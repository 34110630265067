import React from "react";
import { useModalContext } from "../context/ModalContext";
import { Icon } from "@iconify/react/dist/iconify.js";

const pricing = [
  {
    id: 1,
    title: "3 months",
    amount: "1500",
    desc: "Advantages of Paying Monthly (₵1,500/month for 3 months)",
    type: "Monthly",
    features: [
      {
        id: "1",
        title:
          "Flexible payments: Spread the cost over three months, making it easier to manage your budget",
      },
      {
        id: "2",
        title:
          "⁠No large upfront commitment: Ideal if you prefer smaller, manageable payments",
      },
      {
        id: "3",
        title:
          "Access as you go: You pay month by month while progressing through the course",
      },
    ],
  },
  {
    id: 2,
    title: "One time fee",
    amount: "4300",
    desc: "Advantages of Paying a One-Time Fee",
    type: "Subscription",
    features: [
      {
        id: "1",
        title: "Cost savings:Save ₵200 compared to the total monthly payment",
      },
      {
        id: "2",
        title:
          "⁠Simplified process: Pay once and focus fully on learning without worrying about future payments",
      },
      {
        id: "3",
        title:
          "Commitment to the full course: A one-time payment ensures you’re fully enrolled for the entire program",
      },
    ],
  },
];

const PricingSection = () => {
  const { toggleModal } = useModalContext();
  const { selectedPlan, setSelectedPlan } = useModalContext();
  console.log("Selected plan is", selectedPlan);

  const handleSelectedPlan = (plan) => {
    setSelectedPlan(plan);
    toggleModal();
  };

  return (
    <section className="pricing-section" id="pricing">
      <h3>Payment Plans</h3>
      <h2 className="pricing-title">Choose the right plan for you</h2>
      <p className="pricing-description">
        Choose the option that works best for your financial situation and
        learning goals!
      </p>
      <div className="pricing-cards">
        {pricing.map((item, index) => (
          <div className="pricing-card" key={index}>
            <h3 className="plan-title">{item.title}</h3>
            <p className="plan-price">
              {item.amount}
              <span className="plan-price2">
                {item.amount === "1500" ? "/month" : " billed once"}
              </span>
            </p>
            <p className="plan-description">{item.desc}</p>
            <div className="plan-features">
              {item.features.map((feature, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "8px",
                  }}
                >
                  <Icon
                    icon="tabler:checkbox"
                    color="#0096ff"
                    style={{
                      fontSize: "20px",
                      minWidth: "20px",
                      minHeight: "20px",
                    }}
                  />
                  <p
                    style={{
                      textAlign: "left",
                      margin: 0,
                      lineHeight: "1.5",
                    }}
                  >
                    {feature.title}
                  </p>
                </div>
              ))}
            </div>
            <button
              className="get-started-btn"
              onClick={() => handleSelectedPlan(item)}
              style={{ visibility: "hidden" }}
            >
              Get started
            </button>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PricingSection;
