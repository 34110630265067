import { Icon } from "@iconify/react/dist/iconify.js";
import React from "react";

const AboutSection = () => {
  return (
    <section className="about-section animate__animated" id="about">
      <div className="about-content">
        <div>
          <img
            className="logo"
            src="assets/img/icon.png"
            alt="Berth Engineering"
          />
        </div>
        <span className="about-label">Berth Engineering</span>
        <h2>About Us</h2>
        <p>
          <strong>Berth Engineering</strong> is a financial software solutions
          company. Our mission is to empower financial institutions with
          innovative, reliable, and scalable technology, driving progress and
          ensuring operational excellence.
          <br />
          We specialize in building transformative software solutions for
          businesses.
          <br /> <br />
          Some of our notable projects include:
        </p>
      </div>
      <div className="about-stats">
        <div className="stat-card">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <path
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
                d="M3 9.5L12 4l9 5.5M5 20h14M10 9h4m-8 8v-5m4 5v-5m4 5v-5m4 5v-5"
              />
            </svg>
          </h3>
          <h2> Core Banking Systems</h2>
          <span>
            For microfinance institutions in Ghana, streamlining operations and
            enabling financial growth.
          </span>
        </div>
        <div className="stat-card">
          <Icon
            icon="mdi:facial-recognition"
            color="#0096ff"
            fontSize={29}
            style={{ marginBottom: "15px" }}
          />
          <h2>Facial Verification system</h2>
          <span>
            Revolutionizing identity verification for financial institutions
          </span>
        </div>
        <div className="stat-card">
          <h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1em"
              height="1em"
              viewBox="0 0 24 24"
            >
              <g fill="none" stroke="currentColor" stroke-width="1.5">
                <path d="M2 19V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2Z" />
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2 7h20M5 5.01l.01-.011M8 5.01l.01-.011M11 5.01l.01-.011"
                />
              </g>
            </svg>
          </h3>
          <h2> AI-Powered Customer Service Application</h2>
          <span>
            Enhancing user experience through artificial intelligence-driven
            solutions.
          </span>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
