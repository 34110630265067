import React from "react";
import Header from "./components/Header";
import HeroSection from "./components/HeroSection";
import AboutSection from "./components/AboutSection";
import ServicesSection from "./components/ServicesSection";
import FeaturesSection from "./components/FeaturesSection";
import CourseOutline from "./components/CourseOutline";
import PricingSection from "./components/PricingSection";
import FAQSection from "./components/FAQSection";
// import FormSection from "./components/FormSection";
import Footer from "./components/Footer";
import "./App.css";
// import PaystackPop from "@paystack/inline-js";
import Modal from "./components/Modal";
import { useModalContext } from "./context/ModalContext";

function App() {
  const { isModalOpen } = useModalContext();
  return (
    <div className="App">
      <Header />
      <HeroSection />
      <AboutSection />
      <CourseOutline />
      <ServicesSection />
      <FeaturesSection />
      <PricingSection />
      <FAQSection />
      {/* <FormSection /> */}
      {isModalOpen && <Modal />}
      <Footer />
    </div>
  );
}

export default App;
